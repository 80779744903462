<template>
  <div class="get-notified-wrapper">
    <div v-if="title || intro" class="get-notified-head">
      <div v-if="title" class="title" v-text="title" />
      <div v-if="intro" class="description" v-text="intro" />
    </div>
    <div class="get-notified-body">
      <div class="form-wrapper">
        <van-form
          ref="SubmitInfo"
          @submit="handleSubmit"
        >
          <template v-for="(item, index) in formData.dataList">
            <div v-if="item.show" :key="index" class="form-item">
              <template v-if="item.optionsType === 'input'">
                <div v-if="item.key === 'gradeId' && item.custom === false" class="field-grade">
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="item.value"
                    :rules="item.rules"
                    :placeholder="item.name"
                    class="item-field"
                    @click="showGradePicker = true"
                  />
                  <div class="iconfont icon-bottom-dark" @click="showGradePicker = true" />
                </div>
                <template v-else-if="item.key === 'phone' && item.custom === false">
                  <van-field
                    v-model="item.value"
                    :placeholder="item.name"
                    :rules="item.rules"
                    class="item-field field-phone"
                  >
                    <template slot="left-icon">
                      <div class="phone-country-code-container" @click="openCountryCodePopup">
                        <span class="phone-country-code-text">+{{ phoneAreaCode.value }}</span><i class="iconfont icon-bottom-dark icon-left-phone" />
                      </div>
                    </template>
                  </van-field>
                </template>
                <template v-else-if="item.key === 'email' && item.custom === false">
                  <van-field
                    v-model="item.value"
                    :placeholder="item.name"
                    :rules="item.rules"
                    class="item-field"
                    @click="setEmailPattern"
                  />
                </template>
                <template v-else>
                  <van-field
                    v-model="item.value"
                    :placeholder="item.name"
                    :rules="item.rules"
                    class="item-field"
                  />
                </template>
              </template>
              <template v-else-if="item.optionsType === 'select'">
                <van-field
                  readonly
                  clickable
                  name="picker"
                  :value="item.valueName"
                  :rules="item.rules"
                  :placeholder="item.name"
                  class="item-field"
                  @click="item.showPopup = true"
                />
                <div class="iconfont icon-bottom-dark" @click="item.showPopup = true" />
                <van-popup v-model="item.showPopup" position="bottom">
                  <van-picker
                    :ref="'Picker' + index"
                    show-toolbar
                    value-key="name"
                    :columns="item.options"
                    @confirm="onPickerConfirm(index)"
                    @cancel="item.showPopup = false"
                  />
                </van-popup>
              </template>
              <template v-else-if="item.optionsType === 'selectMore'">
                <template>
                  <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="item.valueName"
                    :rules="item.rules"
                    :placeholder="item.name"
                    class="item-field"
                    @click="item.showPopup = true"
                  />
                  <div class="iconfont icon-bottom-dark" @click="item.showPopup = true" />
                  <BottomPopup :show="item.showPopup" @right-icon-handler="onPopupConfirm(index)" @left-icon-handler="closePopup(item)">
                    <template slot="custom-body">
                      <div class="checkbox-wrapper">
                        <van-checkbox-group v-model="item.checked" class="checkbox-wrap" checked-color="#FFAA0A" shape="square">
                          <van-cell
                            v-for="(option, optionIndex) in item.options"
                            :key="optionIndex"
                            clickable
                            :title="option.name"
                            @click="checkboxToggle(index, optionIndex)"
                          >
                            <template #right-icon>
                              <van-checkbox :ref="'checkboxes' + index" :name="optionIndex" />
                            </template>
                          </van-cell>
                        </van-checkbox-group>
                      </div>
                    </template>
                  </BottomPopup>
                </template>
              </template>
            </div>
          </template>
          <van-field v-if="formData.emailShow" class="checkbox-wrapper">
            <template #input>
              <van-checkbox v-model="formData.subStatus" class="subStatus-checkbox normal-grace-checkbox">
                <div class="checkbox-content font-medium">
                  {{ agreementNotice }}
                </div>
              </van-checkbox>
            </template>
          </van-field>
        </van-form>
        <div class="button-wrapper">
          <van-button round type="primary" class="button btn-submit" :text="submit || submitName" :loading="loading" @click="onSubmit" />
        </div>
      </div>
    </div>
    <no-ssr>
      <van-popup v-model="showGradePicker" position="bottom">
        <van-picker
          show-toolbar
          value-key="name"
          :columns="gradeList"
          @confirm="onConfirmGrade"
          @cancel="showGradePicker = false"
        />
      </van-popup>
      <CountryCodePopup ref="CountryCodePopup" :country-code="phoneAreaCode.countryCode" @code-change="countryCallingCodeChangeHandler" />
    </no-ssr>
  </div>
</template>
<script>
import mixin from './mixin.js';
import trackReportMixin from '../Common/CommonMixin/trackReport'
import { oneApi } from 'config/api';
import CountryCodePopup from '@/components/touch/common/CountryCodePopup'
import BottomPopup from '@/components/touch/common/BottomPopup'
import dataReport from 'common/dataReport'
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  components: {
    CountryCodePopup,
    BottomPopup,
  },
  mixins: [mixin, trackReportMixin],
  data() {
    return {
      // 是否显示年级选择控件
      showGradePicker: false,
      gradeId: '',
      checkedValue: [], // 多选选中值
    }
  },
  mounted() {
    this.init();
    this.initFormData()
    this.handleAreaCodeChange()
  },
  methods: {
    // 提交按钮事件
    onSubmit() {
      this.$refs.SubmitInfo.submit();
    },

    // 处理提交按钮操作
    handleSubmit() {
      // 打开Loading
      this.loading = true;
      this.save(() => {
        // this.$Notify({ type: 'success', message: this.successNotice })
        this.$Toast.success({
          message: this.successNotice,
          duration: 3000,
          className: 'long-toast'
        })
      });
    },

    // 数据保存
    async save(callback) {
      // const { verifyKey } = process.env;
      // const token = await gRecaptcha.getToken.call(this, verifyKey)
      // this.$store.commit('verifyToken/SEND_GRC_TOKEN', token)
      this.$store.commit('verifyToken/SEND_GRC_ACTION', 'leads')
      const dataList = this.getDataParamsList().map((item) => {
        if (item.key === 'gradeId') {
          // eslint-disable-next-line no-param-reassign
          item.value = this.gradeId
        }
        return item
      })
      const data = {
        sourceCode: 'touch',
        mediaCode: 'touch_lead',
        tags: [],
        yachProfile: {},
        formData: dataList,
        componentId: this.moduleId,
        componentType: this.type,
        componentName: this.title,
        emailSubscribe: false,
      }

      if (this.clue !== '') {
        data.tags.push(this.clue)
      }

      if (this.host !== '') {
        data.yachProfile.host = this.host
      }

      if (this.secret !== '') {
        data.yachProfile.secret = this.secret
      }

      this.formData.dataList.forEach((item) => {
        if (item.key === 'email' && item.custom === false && this.formData.subStatus) {
          data.emailSubscribe = true
        }
      })

      // 保存数据接口
      const res = await this.$axios.post(oneApi.submitLeads, data, { rewritePostBody: true });
      // 关闭Loading
      this.loading = false;

      // 业务异常阻断
      if (Number(res.code) !== 0) {
        return this.$Notify({
          message: (res.code === -2 || res.code === 10 || res.code === 11) ? this.errorNotice : res.msg,
          type: 'warning',
        });
      }

      // 埋点上报
      this.buttonTrackReport({
        buttonName: this.submitName,
      })
      if (this.$store.state.locale === 'us') {
        window.dataLayer.push({
          'event': 'generatelead_gtm',
        });
      }
      // 留资组件2提交第三方埋点上报
      dataReport.launchReport({
        gtagOptions: {
          name: 'GenerateLead',
          params: {
            event_category: 'GenerateLead',
          },
        },
        fbqOptions: {
          name: 'Lead',
        },
        ttqOptions: {
          name: 'SubmitForm',
        },
      })
      if (this.$store.state.locale === 'tm') {
        this.mediagoTrack()
      }

      // 执行回调
      if (callback) { callback() }
      // 清空表单
      this.initFormData()
      return res
    },

    /**
     * 选择年级事件
     */
    onConfirmGrade(grade) {
      this.showGradePicker = false;
      this.gradeId = grade.value
      this.formData.dataList.forEach((item) => {
        if (item.key === 'gradeId' && item.custom === false) {
          // eslint-disable-next-line no-param-reassign
          item.value = grade.name
        }
      })
    },

    // 打开手机区号选择器
    openCountryCodePopup() {
      this.$refs.CountryCodePopup.openPicker()
    },

    // 修改手机区号逻辑处理
    countryCallingCodeChangeHandler(args) {
      this.phoneAreaCode = args;
      this.handleAreaCodeChange()
    },

    // 手机区号变更事件
    handleAreaCodeChange() {
      this.formData.dataList.forEach((item) => {
        if (item.key === 'phone' && item.custom !== true) {
          this.setPhonePattern(item)
          // eslint-disable-next-line no-useless-return
          if (item.value === '') return
          // this.$refs.formData.validateField('phone')
        }
      })
    },

    // 设置手机号正则校验格式
    setPhonePattern(item) {
      const { reg } = this.phoneAreaCode;
      // eslint-disable-next-line no-param-reassign
      item.rules = [
        {
          required: !!item.required,
          message: this.requiredError,
          trigger: 'onBlur',
        },
        {
          pattern: reg,
          message: this.phoneError,
          trigger: 'onBlur',
          formatter: (value) => value.trim(),
        },
      ]
    },
    // 设置邮箱校验正则
    setEmailPattern() {
      this.formData.dataList.forEach((item) => {
        if (item.key === 'email' && item.custom !== true) {
          // eslint-disable-next-line no-param-reassign
          item.rules = [
            {
              required: !!item.required,
              message: this.requiredError,
              trigger: 'onBlur',
            },
            {
              pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/,
              message: this.emailError,
              trigger: 'onBlur',
              formatter: (value) => value.trim(),
            },
          ]
        }
      })
    },

    onPickerConfirm(index) {
      const [value] = this.$refs[`Picker${index}`][0].getIndexes()
      const options = this.$refs[`Picker${index}`][0].getValues()
      const valueName = options.map((option) => option.name)
      this.formData.dataList[index].value = value
      this.formData.dataList[index].valueName = valueName.join(',')
      this.formData.dataList[index].showPopup = false
    },

    onPopupConfirm(index) {
      const { checked } = this.formData.dataList[index]
      this.checkedValue = checked
      const options = checked.map((checkedIndex) => this.formData.dataList[index].options[checkedIndex].name)
      this.formData.dataList[index].value = checked
      this.formData.dataList[index].valueName = options.join(',')
      this.formData.dataList[index].showPopup = false
    },
    closePopup(item) {
      // eslint-disable-next-line no-param-reassign
      item.showPopup = false
      // eslint-disable-next-line no-param-reassign
      item.checked = this.checkedValue
    },

    checkboxToggle(index, optionIndex) {
      this.$refs[`checkboxes${index}`][optionIndex].toggle()
    },
    // 百度广告投放埋点
    mediagoTrack() {
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa = window._megoaa || [];
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa.push(
        {
          type: 'event', name: 'Lead', acid: '25112', pxd: '781876808337301',
        },
      );
    },
  },
}
</script>
<style>
.long-toast {
  width: 70vw;
}
</style>
<style lang="scss" scoped>

.get-notified-wrapper {
  padding: 20px 20px 0 20px;
}

.get-notified-head {
  .title {
    font-size: 22px;
    color: var(--txt-color-lv1);
    line-height: 28px;
    font-weight: bold;
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    margin-top: 16px;
    font-size: 14px;
    color: var(--txt-color-lv2);
    line-height: 20px;
    text-align: center;
    word-wrap: break-word;
  }
}

.get-notified-body {
  margin-top: 16px;

  .form-wrapper {
    .form-item {
      position: relative;

      .icon-bottom-dark {
        position: absolute;
        right: 20px;
        top: 13px;
        color: #171717;
        font-size: 22px;
      }
    }

    .item-field {
      padding: 10px 16px;
      margin-bottom: 10px;
      border: 1px solid #e7e9ed;
      border-radius: 8px;
      overflow: visible;

      /deep/.van-field__control {
        padding-right: 20px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        color: var(--txt-color-lv1);

        &::placeholder {
          font-size: 16px;
          font-weight: 500;
          color: #676769;
          line-height: 30px;
        }
      }

      &::after {
        border-bottom: none;
      }

      &.van-field--error {
        margin-bottom: 35px;

        /deep/.van-field__control,
        /deep/.van-field__control::placeholder {
          color: var(--txt-color-lv2) !important;
        }
      }

      /deep/ .van-field__error-message {
        position: absolute;
        top: 45px;
      }

      /deep/ &.field-phone .van-field__error-message {
        left: -82px;
      }
    }

    .field-grade {
      position: relative;

      .icon-bottom-dark {
        position: absolute;
        right: 20px;
        top: 13px;
        color: #171717;
        font-size: 22px;
      }
    }
  }

  .checkbox-wrapper {
    padding: 6px 0 0 0;
  }

  .subStatus-checkbox {
    .checkbox-content {
      font-size: 14px;
      line-height: 20px;
      color: var(--txt-color-lv2);

      .link-text {
        text-decoration: underline;
        color: #3370ff;
      }
    }
  }

  .button-wrapper {
    margin-top: 30px;
    text-align: center;

    .btn-submit {
      font-weight: bold;
      line-height: 50px;
      width: 285px;
      height: 50px;
      background: var(--txt-color-link);
      border-radius: 25px;

      /deep/ .van-button__text {
        font-size: 16px;
      }
    }
  }

  .van-icon {
    position: absolute;
    top: 18px;
    right: 20px;
  }

  .phone-country-code-container {
    display: flex;
    align-items: center;
    margin: 4px 16px 0 0;
    width: 62px;

    .phone-country-code-text {
      display: block;
      width: 40px;
      font-size: 14px;
      color: #262729;
    }

    .iconfont {
      font-size: 22px;
    }

    .icon-left-phone {
      left: 46px;
    }
  }
}
</style>
