<template>
  <div>
    <div class="SuctionBottom_f" id="SuctionBottom_f" v-if="SuctionBottomStatus">
      <div class="content">
        <!-- <div class="iconfont icon-close" @click="close()" /> -->
        <div class="description">
          {{ copywriting }}
        </div>
        <div class="button_content">
          <div v-for="(item, index) in dataList" :key="index" class="button btn-primary" @click="handleAccept(item)">
            {{ item.buttonCopywriting }}
          </div>
        </div>
      </div>
    </div>

    <div class="SuctionBottom_r" id="SuctionBottom_r">
      <div class="content">
        <div class="description">
          {{ copywriting }}
        </div>
        <div class="button_content">
          <div v-for="(item, index) in dataList" :key="index" class="button btn-primary" @click="handleAccept(item)">
            {{ item.buttonCopywriting }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import mixin from './mixin';
  import bus from 'common/bus'
  import trackReportMixin from '../Common/CommonMixin/trackReport'

  export default {
    mixins: [mixin, trackReportMixin],
    data() {
      return {
        SuctionBottomStatus: true
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      handleScroll() {
        const SuctionBottom_f = document.getElementById('SuctionBottom_r');
        const height = SuctionBottom_f.offsetHeight;

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const element = document.querySelector('.SuctionBottom_r');
        const rect = element.getBoundingClientRect();
        const offset = rect.top - document.documentElement.clientHeight;
        if ((offset + height) > 0) {
          this.SuctionBottomStatus = true
        } else {
          this.SuctionBottomStatus = false
        }
      },
      handleAccept(item) {
        console.log(item)
        if (item.skip == '_blank') { // 功能性跳转
          // 埋点上报
          this.buttonTrackReport({
            buttonName: 'toShowDiagnosis',
          });
          if (!this.isLogin) {
            // bus.$emit('login', {triggerSource: 'diagnosis'});
            const currentFullPath = this.$route.fullPath
            const backUrl = encodeURI(currentFullPath)
            this.$router.replace(`/login?cancelUrl=${backUrl}&successUrl=${backUrl}&triggerSource=appointment`)
            return
          } else {
            // bus.$emit('toShowDiagnosis', { type: 1})
            const currentFullPath = this.$route.fullPath
            const backUrl = encodeURI(currentFullPath)
            this.$router.replace(`/appointmentDiagnosis?successUrl=${backUrl}`);
          }
        }
        if (item.skip == '_self') { // 自定义链接跳转
          if (item.skipWay == '_blank') { // 在当前页面打开
            window.location.href = item.skipLink;
          }
          if (item.skipWay == '_self') { // 在新标签页打开
            window.open(item.skipLink, '_blank');
          }
        }
      },
      close() {
        this.SuctionBottomStatus = false
      }
    },
    computed: {
      isLogin() {
        return this.$store.state.ucenter.isLogin;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .SuctionBottom_f {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100vw;
    padding-left: 16px;
    padding-right: 16px;
    background: #FFFFFF;
  }

  .SuctionBottom_r {
    position: relative;
    width: 100vw;
    padding-left: 16px;
    padding-right: 16px;
    background: #FFFFFF;
  }

  .content {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #FF9F0A;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 auto;
  }

  .description {
    width: 311px;
    /* height: 40px; */
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 20px;
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin: 0 auto;
    /* padding-right: 20px; */
  }

  .button_content {
    display: flex;
    flex-direction: column;
  }

  .button {
    width: 311px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 16px;
    background: #fff;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF9F0A;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .iconfont {
    position: absolute;
    font-size: 28px;
    right: 16px;
    top: 4px;
    margin-right: 4px;
    color: #FFFFFF;
  }
</style>